import { shortListPageJs } from "./shortList";

$(function () {
    // Only run on company search pages
    if (!$('.offering-page, .short-list-company-page, .company-search-page').length) {
        return;
    }

    function searchPage(page, sortType, orderType, offering, scrollIntoView = false, showAllCompanies = false, refreshOnlyCompanies = true) {
        var query = "?p=" + page;

        // Page has a search
        if ($('#company-search-query').length) {
            var q = $('#company-search-query').val();
            query += "&q=" + encodeURIComponent(q);
        }

        if (offering != '' && typeof offering != "undefined") {
            query += "&s=" + offering;
        }

        if (sortType != '') {
            query += "&sortType=" + sortType;
        }

        if (orderType != '') {
            query += "&orderType=" + orderType;
        }

        if (showAllCompanies === true) {
            query += "&showAllCompanies=true";
        }

        query += "&refreshOnlyCompanies=" + refreshOnlyCompanies;

        $.ajax({
            url: companySearchOptions.searchUrl + query,
            type: 'POST',
        })
        .done(function (data) {
            if (refreshOnlyCompanies) {
                $("#company-search-container").html(data);                
            } else {
                $("#search-result-container").html(data);
            }            

            // Re-attach pagination, sort and shortlist functionality
            bindPagination();
            bindSort();
            shortListPageJs.bindShortList();

            if (scrollIntoView) {
                // Scroll to results top
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("ul#sort-type").offset().top
                }, 1000);
            }
        });
    }

    function updatePagination(page, sortType, orderType, offering) {
        const url = new URL(window.location);

        url.searchParams.set('p', page);

        if (sortType != '') {
            url.searchParams.set('sortType', sortType);
        }
        else {
            url.searchParams.delete('sortType');
        }

        if (orderType != '') {
            url.searchParams.set('orderType', orderType);
        }
        else {
            url.searchParams.delete('orderType');
        }

        if (offering != '' && typeof offering != "undefined") {
            url.searchParams.set('s', offering);
        }
        else {
            url.searchParams.delete('s');
        }

        window.history.replaceState(null, '', url.toString());
    }

    function bindPagination() {
        $('.pagination .pagination__page a').on('click.pagination', function (e) {
            e.preventDefault();

            var pageNumber = $(this).data("page");
            var sortType = $(this).data("sort-type");
            var orderType = $(this).data("order-type");
            var offering = $(this).data("offering");

            if (orderType == 'Asc' || typeof orderType == 'undefined') {
                orderType = '';
            }

            if (sortType == 'Name' || typeof sortType == 'undefined') {
                sortType = '';
            }

            updatePagination(pageNumber, sortType, orderType, offering);
            searchPage(pageNumber, sortType, orderType, offering, true);

            return false;
        });
    }

    function bindSort() {
        $('ul#sort-type a').on('click.sort', function (e) {
            e.preventDefault();

            var { pageNumber, sortType, orderType, offering, showAllCompanies } = GetSearchParameters(this);

            updatePagination(pageNumber, sortType, orderType, offering);
            searchPage(pageNumber, sortType, orderType, offering, false, showAllCompanies);

            return false;
        });
    }

    function GetSearchParameters(currentInstance) {
        var pageNumber = 1;
        var sortType = $(currentInstance).data("sort-type");
        var orderType = $(currentInstance).data("order-type");
        var showAllCompanies = $(currentInstance).data("show-all");
        var offering = $(currentInstance).data("offering");

        if (orderType == 'Asc' || typeof orderType == 'undefined') {
            orderType = '';
        }

        if (sortType == 'Name' || typeof sortType == 'undefined') {
            sortType = '';
        }

        if (typeof showAllCompanies != 'undefined') {
            showAllCompanies = true;
        }
        return { pageNumber, sortType, orderType, offering, showAllCompanies };
    }

    bindPagination();
    bindSort();

    $('#submit-search').on('click', function (e) {
        e.preventDefault();
        var { pageNumber, sortType, orderType, offering, showAllCompanies } = GetSearchParameters(this);
        updatePagination(pageNumber, sortType, orderType, offering);
        var refreshOnlyCompanies = document.getElementById('RefreshOnlyCompanies');
        if (refreshOnlyCompanies) {
            refreshOnlyCompanies = refreshOnlyCompanies.value.toLowerCase() === 'true';
        } else {
            refreshOnlyCompanies = true;
        }
        searchPage(pageNumber, sortType, orderType, offering, false, showAllCompanies, refreshOnlyCompanies);        
    });
})