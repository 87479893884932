
import { companyCookieJs } from "./companyCookie.js";
import { CopyToClipboard } from "../forms.js";

export var shortListPageJs = {
    // TODO: Bind with jQuery instead
    BtnExportShortList: function (textForCopy) {
        CopyToClipboard(textForCopy, "The link is copied.");
    },

    bindShortList: function () {
        $("button.shortlist-action").unbind('click.shortlist');
        $("button.shortlist-action").on('click.shortlist', function (e) {
            e.preventDefault();

            // Disable all buttons while processing
            $("button.shortlist-action").prop("disabled", true);
            $(this).addClass("company-card__button--loading disabled");

            // Try to add company in shortlist
            var companyId = $(this).attr("data-companyid");
            var forceRefresh = $(this).is("[data-force-refresh]");
            var companyInShortList = shortListPageJs.addToShortList(companyId);

            if (forceRefresh) {
                // Page will be reloaded, no further actions needed
                location.reload(true);
            }
            else {
                if (companySearchOptions.triggerUrl != 'undefined') {
                    $.ajax({
                        url: companySearchOptions.triggerUrl,
                        type: 'POST'
                    })
                    .done(function (data) {
                        var triggerHtml = $(data).html();
                        $("div.short-list-trigger").html(triggerHtml);
                    })
                    .always(function () {
                        var button = $("button.shortlist-action.disabled");
                        var companyId = $(button).data("companyid");
                        var companyInShortList = companyCookieJs.isCompanyInCookie(companyId);

                        // Re-enable all buttons after processing
                        $("button.shortlist-action").prop("disabled", false);
                        $("button.shortlist-action").removeClass("company-card__button--loading disabled");

                        // Update button status
                        if (companyInShortList) {
                            $(button).find("span.shortlist-add").addClass("d-none");
                            $(button).find("span.shortlist-remove").removeClass("d-none");
                        }
                        else {
                            $(button).find("span.shortlist-remove").addClass("d-none");
                            $(button).find("span.shortlist-add").removeClass("d-none");
                        }
                    });
                }
            }
        });
    },

    addToShortList: function (companyId) {
        if (!(companyId.length)) {
            console.log("Company id is missing or in wrong format.");
            return false;
        }

        var isCompanyInCookie = companyCookieJs.isCompanyInCookie(companyId);

        if (isCompanyInCookie) {
            companyCookieJs.removeCompanyFromCookie(companyId);
        }
        else {
            companyCookieJs.addCompanyToCookie(companyId);
        }

        // TODO: Verify that the cookie was actually written (return actual result from above operations instead)
        isCompanyInCookie = companyCookieJs.isCompanyInCookie(companyId);
        return isCompanyInCookie;
    }
}

$(function () {
    // Bind shortlist button events
    shortListPageJs.bindShortList();
});