// @ts-check

/**
 * Add icons to external links
 * @param {string} ariaLabel Translated text for icon aria-label
 */
export function processExternalLinks(ariaLabel) {
  const targetSelector = ".image-links__link-text"; // can be extended with more classes
  const icon =
    '<svg aria-label="' +
    ariaLabel +
    '" xmlns="http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" viewBox = "0 0 73.18 72.86" > <defs><style>.cls-1{fill: CurrentColor;}.cls-2{clip - path: url(#clip-path);}.cls-3{fill: CurrentColor;}</style><clipPath id="clip-path" transform="translate(-8.41 -8.57)"><rect class="cls-1" width="90" height="90" /></clipPath></defs><title>External link</title> <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g class="cls-2"><polygon class="cls-3" points="73.18 0 73.18 31.55 69.18 31.55 69.18 6.82 35.07 40.93 32.24 38.1 66.34 4 41.63 4 41.63 0 73.18 0" /><polygon class="cls-3" points="59.18 72.86 0 72.86 0 13.68 29.59 13.68 29.59 17.68 4 17.68 4 68.86 55.18 68.86 55.18 42.75 59.18 42.75 59.18 72.86" /></g></g></g></svg>';
  const links = document.links;
  for (var i = links.length; i-- > 0; ) {
    let link = links[i];
    if (
      link.hostname != location.hostname &&
      link.hostname != location.hostname.replace("www.", "")
    ) {
      link.className += " external-link";
      let elToAttachIcon = link.querySelector(targetSelector) ?? link;
      elToAttachIcon.insertAdjacentHTML("beforeend", icon);
    }
  }
}
