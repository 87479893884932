import FloatLabels from "float-labels.js/dist/float-labels.min.js";

// GLOBAL FUNCTIONS YOU CAN CALL THESE ANYWHERE
/**
 * Get textarea counter
 * @param {any} $el Any html or jquery element
 * @returns {string} The current and max count of textarea
 */
function textareaGetCount($el) {
    var max_length = $el.attr("maxlength");
    var current_length = $el.val().length;

    return current_length + " / " + max_length;
}

/** Add textAreaCounter to textarea */
function addTextAreaCounter() {
    var $textareacontainer = $(this);
    var $textarea = $textareacontainer.find('.form-element__textarea');
    var max_length = $textarea.attr("maxlength");

    if (max_length && $textareacontainer.find('.form-element__textarea-counter').length === 0) {
        var $counter = $("<span class='form-element__textarea-counter'>").html(textareaGetCount($textarea));
        $textarea.after($counter);

        $textarea.on("keyup change paste", function () {
            $counter.html(textareaGetCount($textarea));
        });
    }
}


// Init float labels.js library
var floatLabels;
window.buildFloatLabels = function () {
    if (typeof floatLabels  !== "undefined") {
        floatLabels.rebuild();
    } else {
        floatLabels = new FloatLabels('form', {
            style: 2,
            customLabel: function (labelEl, el) {
                var required = $(el).attr('required');
                return labelEl.textContent + (typeof required !== 'undefined' && required !== false ? '*' : '');
            },
            customPlaceholder: function (placeholderText, el, labelEl) {
                var required = $(el).attr('required');
                return placeholderText + (typeof required !== 'undefined' && required !== false ? '*' : '');
            }
        });
    }
}


/** Add counter with current and max length to every textarea using jquery each helper */
export function addCounterToEveryTextArea() {
    $(".form-element__textarea-container").each(addTextAreaCounter)
};

/**
 * Selects all checkboxes by market container id
 * @param {HTMLElement} containerId the html element that contains all the checkboxes
 */
export function selectAll(el, containerId) {
    var checkboxes = $("#" + containerId).find('input[type="checkbox"]');
    if (checkboxes.length > 1) {
        checkboxes.prop('checked', el.checked);
    }
}

/**
 * Selects the parent check box.
 */
export function selectParent(parentId) {
    var elParent = $("#keyoffering-" + parentId);
    if (elParent) {
        elParent.prop('checked', true);
    }
}

/**
 * Unchecks all checkboxes if the parent was unchecked.
 */
export function deselectChildren(parentId) {
    if ($("#keyoffering-" + parentId).is(':checked') === false) {
        $("#sub-" + parentId)
            .find('input[type="checkbox"]')
            .each(function (i, cb) {
                cb.checked = false;
            });
    }
}

/**
 * Unchecks select all checkbox by market container id if all countries are not checked. Assumes that the select all checkbox is first checkbox element
 * @param {HTMLElement} marketContainerId The html element that contains all the checkboxes
 */
export function selectAllCheckOrUncheck(el) {
    var rootEl = el.closest('div.form-collapse__content-inner');
    if (rootEl) {
        var selectAllEl = $(rootEl).find('input[type="checkbox"]:first');
        if (selectAllEl) {
            if (el.checked) {
                // The element was checked. Let's make sure that the Select all checkbox is checked if all the child elements are checked!
                var checkboxesCount = $(rootEl).find('input[type="checkbox"]').length;
                var checkedCheckboxesCount = $(rootEl).find('input[type="checkbox"]:checked').length;
                if (checkedCheckboxesCount === checkboxesCount - 1) {
                    selectAllEl.prop('checked', true);
                }
            }
            else {
                // The element was unchecked. Let's make sure that the Select all checkbox is not checked! 
                selectAllEl.prop('checked', false);
            }
        }
    }
}

/**
 * Get all checked checkboxes by market
 * @param {HTMLElement} marketContainerId The html element that contains all the checkboxes
 * @param {HTMLElement} marketCountId The html element id that contains the count text
 * @param {boolean} isSelectAllPresent Is select all checkbox present and the functionality for that needed. Defaults to true
 * @returns Empty if no count. Otherwise returns the count of checked checkboxes by market
 */
export function getCheckedCount(marketContainerId, marketCountId) {
    var checkedCount = $(marketContainerId).find('input[type="checkbox"]:checked').length;
    return checkedCount > 0 ? marketCountId.innerText = checkedCount.toString()  : marketCountId.innerText = '';
}

/**
 * Toggles the defined form area. It collapses to show the checkboxes or hides them if they are shown.
 * @param {HTMLElement} btnId Button element id
 */
export function toggleArea(btnId) {
    var curEl = $(btnId);
    var controlId = curEl.attr('aria-controls');
    var controlEl = $("#" + controlId);
    if (controlEl.is(':visible')) {
    controlEl.hide(); 
    curEl.attr('aria-expanded', "false");
   } 
   else {
    controlEl.show(); 
    curEl.attr('aria-expanded', "true");
   }
}

export function CopyToClipboard(text, successmsg) {
    var successmsgText = successmsg ? successmsg : "The link is copied.";
    var sampleTextarea = document.createElement("textarea");
    document.body.appendChild(sampleTextarea);
    sampleTextarea.value = text; //save main text in it
    sampleTextarea.select(); //select textarea contents
    document.execCommand("copy");
    document.body.removeChild(sampleTextarea);
    alert(successmsgText);
}
// GLOBAL FUNCTIONS END HERE

$(function () {
    addCounterToEveryTextArea();

    // File input
    $('.form-element__label--file').each(function() {
        var $label = $(this);
        var $input = $label.siblings('.form-element__file');
        var $deleteBtn = $label.siblings('.form-element__file-delete');
        var required = typeof $input.attr('required') !== 'undefined' && $input.attr('required') !== false;
        var defaultLabel = $label.html() + (required ? '*' : '');

        // Set change listener
        $label.next('.form-element__file').on('change', function(){
            if($(this).val()) {
                var filename = $(this).val().replace(/^.*[\\\/]/, '');
                var $input = $(this).prev('.form-element__label--file');
                $label.html(filename);

                // Show delete button
                $deleteBtn.removeClass('visually-hidden');
            }
            else {
                $label.html(defaultLabel);   

                // Hide delete button
                $deleteBtn.addClass('visually-hidden');
            }
        });

        // Set delete
        $deleteBtn.on('click', function() {
            $input.val('');
            $input.trigger('change');
        });

        // Set default label with mandatory sign
        if(required) {
            $label.html(defaultLabel);
        }
    });

    // Delete existing file
    $('.form-element__file-container').each(function () {
        var $fileContainer = $(this);
        var $fileEl = $fileContainer.parent();
        var $uploadEl = $fileEl.siblings('.form-element__file-element');
        var $fileDeletedEl = $fileContainer.siblings('.form-element__existing-file-deleted');
        var $deleteBtn = $fileContainer.children('.form-element__file-delete');
        var $fileInputElFind = $fileContainer.parents().get().find((el => $(el).find('input[type="file"]').length));
        var $fileInputEl = $('input[type="file"]', $fileInputElFind);
        
        $deleteBtn.on('click', function (e) {
            e.preventDefault();
            $fileEl.hide();
            $uploadEl.show();
            if ($fileInputEl.attr("data-required")) {
                $fileInputEl.attr("required", "required");
            }
         
            $fileDeletedEl.val(true);
        });
    });

    window.buildFloatLabels();

    $(document).on('click', '.form-element__delete', function (e) {
        e.preventDefault(); 
        var $elDiv = $(this).closest('.form-element-delete'); 
        if ($elDiv.length > 0) {
            $elDiv.remove();
        }
    });
});
