$(function () {
    if (!$('.filtering-block').length) {
        return;
    }

    function SearchAndSetResult(blockId, loadMore) {
        var $form = $('#filtering-form-' + blockId);
        var $allItems = $('#all-items-' + blockId);

        if ($form.length && $allItems.length) {
            var q = "";
            var $queryInput = $('input[name="query-' + blockId + '"]')//$block.find('.search-field__input');
            if ($queryInput.length) {
                q = $queryInput.val();
            }
            var articleTypes = $.map($('input[name="articleType-' + blockId + '"]:checked'), function (n, i) {
                return n.value;
            }).join(';');
            
            if (loadMore) {
                // Update page attribute. If we are loading more we need to fetch the next page.
                $form.data('currentPage', $form.data("currentPage") + 1);
            }
            else {
                // User has selected to filter items so we need to fetch the first page
                $form.data('currentPage', 1);
            }

            let data = {
                blockId: blockId,
                q: encodeURIComponent(q),
                page: $form.data("currentPage"),
                articleTypes: articleTypes
            };

            $.ajax({
                url: "/ContentFilteringBlock/Search",
                type: "POST",
                contentType: "application/json; charset=utf-8",
                dataType: "html",
                data: JSON.stringify(data)
            })
                .done(function (result) {
                    var html = $('<div />').append(result);
                    var $divItems = html.find("#more-items");
                    if ($divItems.length) {
                        if (!loadMore) {
                            // We need to remove all the old items before adding the new ones
                            $allItems.empty();
                        }
                        var moreAvailable = $divItems.data("moreAvailable");
                        var items = $divItems.find('a.news__item');
                        if (items.length > 0) {
                            items.each(function () {
                                $allItems.append($(this));
                            });
                            // Do we need to hide load more button?
                            if (moreAvailable == "False") {
                                $('#load-more-' + blockId).hide();
                            }
                            else {
                                // We need to add the load more button
                                $('#load-more-' + blockId).show();
                            }
                        }
                    }
                })
                .fail(function (error) {
                    console.error('Error:', error);
                })
        }
    }

    $('.filtering-block').each(function () {
        var $block = $(this);
        var blockId = $block.data("blockId");

        $('#submit-' + blockId).on('click', function (e) {
            e.preventDefault();
            SearchAndSetResult(blockId, false);
            return false;
        });

        $('#load-more-' + blockId).on('click', function (e) {
            e.preventDefault();
            SearchAndSetResult(blockId, true);
            return false;
        });

        $('input[name="articleType-' + blockId + '"]').on('click', function (e) {
            $('#filtering-form-' + blockId).data('page', 1);
            SearchAndSetResult(blockId, false);
        });
    });
})