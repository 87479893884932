const TABS_STRINGS = {
    ariaControls: 'aria-controls',
    ariaSelected: 'aria-selected', 
    classTabActive: 'tabs__tab--active',
    classPanelHidden: 'tabs__panel--hidden',
    false: 'false',
    keyArrowLeft: 'ArrowLeft',
    keyArrowRight: 'ArrowRight',
    keyHome: 'Home',
    keyEnd: 'End',
    roleTab: '[role=tab]',
    roleTabPanel: '[role=tabpanel]',
    tabIndex: 'tabindex',
    true: 'true',
};

class Tabs {
    constructor(container) {
        this.tabs = Array.from(container.querySelectorAll(TABS_STRINGS.roleTab));
        this.tabpanels = Array.from(container.querySelectorAll(TABS_STRINGS.roleTabPanel));
        this.firstTab = this.tabs[0];
        this.lastTab = this.tabs[this.tabs.length - 1];

        this.tabs.forEach((tab) => {
            tab.tabIndex = -1;
            tab.setAttribute(TABS_STRINGS.ariaSelected, TABS_STRINGS.false);
            tab.addEventListener('keydown', this.handleKeydown.bind(this));
            tab.addEventListener('click', this.handleClick.bind(this));
        });

        this.selectTab(this.firstTab);
    }

    selectTab(selectedTab) {
        this.tabs.forEach((tab, i) => {
            if (selectedTab === tab) {
                tab.setAttribute(TABS_STRINGS.ariaSelected, TABS_STRINGS.true);
                tab.removeAttribute(TABS_STRINGS.tabIndex);
                tab.classList.add(TABS_STRINGS.classTabActive);
                this.tabpanels[i].classList.remove(TABS_STRINGS.classPanelHidden);
            } else {
                tab.setAttribute(TABS_STRINGS.ariaSelected, TABS_STRINGS.false);
                tab.tabIndex = -1;
                tab.classList.remove(TABS_STRINGS.classTabActive);
                this.tabpanels[i].classList.add(TABS_STRINGS.classPanelHidden);
            }
        });
    }

    handleClick({ currentTarget }) {
        this.selectTab(currentTarget);
    }

    handleKeydown(event) {
        const { currentTarget, key } = event;

        switch (key) {
            case TABS_STRINGS.keyArrowLeft:
                this.focusPreviousTab(currentTarget);
                this.stopAndPrevent(event);
                break;

            case TABS_STRINGS.keyArrowRight:
                this.focusNextTab(currentTarget);
                this.stopAndPrevent(event);
                break;

            case TABS_STRINGS.keyHome:
                this.firstTab.focus()
                this.stopAndPrevent(event);
                break;

            case TABS_STRINGS.keyEnd:
                this.lastTab.focus()
                this.stopAndPrevent(event);
                break;

            default:
                break;
        }
    }

    focusPreviousTab(tab) {
        if (tab === this.firstTab) {
            this.lastTab.focus();
        } else {
            const i = this.tabs.indexOf(tab);
            this.tabs[i - 1].focus();
        }
    }

    focusNextTab(tab) {
        if (tab === this.lastTab) {
            this.firstTab.focus();
        } else {
            const i = this.tabs.indexOf(tab);
            this.tabs[i + 1].focus();
        }
    }

    stopAndPrevent(event) {
        event.stopPropagation();
        event.preventDefault();
    }
}

/**
 * Init Tabs here.
 * Multiple instances can be created using html id.
 * It is good practise to use id instead of class, because ids are meant to be unique.
 */
window.addEventListener('load', () => {
    const companyPreviewTabs = document.querySelector('#company-preview-tabs');
    if (companyPreviewTabs) {
        new Tabs(companyPreviewTabs);
    }
});