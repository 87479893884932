export const companyCookieJs = {
    CookieName: 'BusinessFinland_CompaniesShortList',

    isCompanyInCookie: function(companyId) {
        let value = this.getCompanyCookie();
        if (value == "" || companyId == "") return false;
        if (jQuery.type(companyId) === "number") {
            companyId = companyId.toString();
        }
        return value.toUpperCase().indexOf(companyId.toUpperCase()) != -1;
    },

    addCompanyToCookie: function (companyId) {
        if (this.isCompanyInCookie(companyId)) return;
        let value = this.getCompanyCookie();
        if (value.split('|').length >= 100) {
            alert('Cannot add more than 100 companies to ShortList.')
        }
        else {
            this.setCompanyCookie(value.concat((value ? '|' : ''), companyId));
        }        
    }, 

    removeCompanyFromCookie: function (companyId) {
        if (!this.isCompanyInCookie(companyId)) return;

        let value = this.getCompanyCookie().toUpperCase();
        let companies = value.split('|');
        companies.splice(companies.indexOf(companyId.toUpperCase()), 1);
        this.setCompanyCookie(companies.join('|'));
    },

    amountShortListed: function() {
        let value = this.getCompanyCookie();
        if (value != "") {
            let companies = value.split('|');
            return companies.length;
        }
        return 0;
    },

    removeCookie: function() {
        document.cookie = CookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/'
    },

    getCompanyCookie: function() {
        return this.getCookieValue(this.CookieName);
    },

    setCompanyCookie: function (value) {
        document.cookie = this.CookieName + '=' + value + ';expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/;samesite=lax';
    },

    getCookieValue: function(name) {
        let result = document.cookie.match("(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)")
        return result ? result.pop() : "";
    }
}