import { bindContacts } from "./contacts";

$(function () {
    // Only run on company search pages
    if (!$(".contact-us-page").length) {
        return;
    }

    // Only run if filters are present // TODO: Make generic, allowing multiple filter sets / searches on page
    if ($("#contactUsPageFilters").length) {
        $("#contactUsPageFilters").on("change", function () {
            // Get filters as anonymous objects of { name: value }
            var filters = $.map($(this).find("select"), function (select) {
                if ($(select).attr("data-term")) {
                    if ($(select).val()) {
                        var filter = {};
                        filter[$(select).data("term")] = $(select).val();
                        return filter;
                    }
                }
            });

            if (filters.length) {
                // Create parameters
                var filterParams = {};

                $.each(filters, function (index, value) {
                    $.extend(filterParams, value);
                });

                var params = $.param(filterParams);
                var searchUrl = contactSearchOptions.searchUrl + "?" + params;

                // Clear results, open results container and show loader
                $("#contactUsPageResults").attr("data-results", params);
                $("#contactUsPageResults").html("");
                $("#contactUsPageResultsContainer").addClass("show");
                $("#contactUsPageResultsContainer .spinner-border").addClass("show");
                $("#contactUsPageContent").hide();

                $.ajax({
                    url: searchUrl,
                    dataType: "html",
                    type: "GET",
                    success: function (data) {
                        // Results container must match results searched
                        if ($("#contactUsPageResults").attr("data-results") === params) {
                            $("#contactUsPageResults").html(data);
                            $("#contactUsPageResultsContainer .spinner-border").removeClass("show");

                            // Bind dynamically created modals + show more (contacts.js)
                            bindContacts();
                        }
                    }
                });
            }
            else {
                // No filters selected, hide results and show content
                $("#contactUsPageResults").html("");
                $("#contactUsPageResultsContainer").removeClass("show");
                $("#contactUsPageResultsContainer .spinner-border").removeClass("show");
                $("#contactUsPageContent").show();
            }
        });
    }
})
