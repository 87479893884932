export function showCurrentValueProposition(title, content) {
    if (title && content) {
        var titleEl = $('#current-value-proposition-title');
        var contentEl = $('#current-value-proposition-content');

        if (titleEl && titleEl.text() === title &&
            contentEl && contentEl.text() === content) {
            // content and title is the same dont do anything.
            return;
        }

        if (titleEl) {
            titleEl.text(title)
        }

        if (contentEl) {
            contentEl.text(content)
        }
    }
}