var handbookEl = document.querySelector("body.handbook main");
if (handbookEl) {
    document.addEventListener("DOMContentLoaded", function (e) {
        // Add lightbox when using handbook.
        // In order for the lightbox to work the image needs to be surrounded by a link tag.
        if (handbookEl) {
            e.preventDefault();
            var images = handbookEl.querySelectorAll("img");
            if (images && images.length) {
                for (var k = 0; k < images.length; k++) {
                    var image = images[k];
                    // Do not make a modal for the image if the image is already inside a modal.
                    if (!image.classList.contains("modal-close-image-size") &&
                        !image.parentElement.classList.contains("modal-body")) {
                        var lightboxName = image.src.split("/").pop().replace(/\./g, "-") + k + "-modal";
                        var modalButton = document.createElement("button");
                        var modalButtonId = "modalButton-" + lightboxName;
                        var modalAsTemplate = `<div class="modal" id="${lightboxName}" tabindex="-1">
                    <div class="modal-dialog modal-lg modal-fullscreen-sm-down">
                        <div class="modal-content">
                            <div class="modal-header border-0">
                                <button type="button" class="btn btn--secondary modal-no-button-styles ms-auto" data-bs-dismiss="modal" aria-label="Close modal">
                                    <img loading="lazy" src="/icons/close-icon.svg" class="modal-close-image-size" alt="" width="40" height="40">
                                </button>
                            </div>
                            <div class="modal-body ms-auto me-auto">
                            ${image.outerHTML}
                            </div>
                        </div>
                    </div>
                </div>`;
                        modalButton.setAttribute("type", "button");
                        modalButton.setAttribute("id", modalButtonId);
                        modalButton.setAttribute("class", "modal-no-button-styles");
                        modalButton.setAttribute("data-bs-toggle", "modal");
                        modalButton.setAttribute("data-bs-target", "#" + lightboxName);
                        modalButton.setAttribute("aria-controls", lightboxName);
                        modalButton.setAttribute("aria-label", "Open the current image in a modal as a larger version of the image");
                        image.parentNode.insertBefore(modalButton, image);
                        modalButton.appendChild(image);
                        modalButton.insertAdjacentHTML("afterend", modalAsTemplate);
                    }
                }
            }
        }
    });
}
