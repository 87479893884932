// Hero for offerings
function toggleButtonShowMore(e) {
  e.preventDefault();
  var block = $('.showmore');
  block.toggleClass('d-block');
  if(block.hasClass('d-block')) {
      $('.button-show').text(`${showLessText}`);
      block[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  } else {
    $('.button-show').text(`${showMoreText}`);   
  }
}

$('.button-show').on('click', toggleButtonShowMore);
$('.close-button').on('click', function (e) {
    toggleButtonShowMore(e);
    $("#showmoredesktop").focus();
});
$('.close-button-mobile').on('click', function (e) {
    toggleButtonShowMore(e);
    $("#showmoredesktop").focus();
});