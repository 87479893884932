function bindContactShowMore() {

    // Add show more link to industries texts that have overflow
    $(".contact-industries .showMore-container").each(function (e) {
        if ($(this)[0].scrollWidth > $(this)[0].offsetWidth) {
            var showMoreText = $(this).attr("data-showMoreText");
            var showAllLink = $('<a href="#" class="showMore" />');
            $(showAllLink).html(showMoreText);
            $(this).append(showAllLink);
        }
    });

    // Add show more link functionality
    $(".contact-industries .showMore").on("click", function (e) {
        e.preventDefault();

        var showMoreContainer = $(this).parent(".showMore-container");
        $(showMoreContainer).toggleClass("showMore-collapsed");

        if ($(showMoreContainer).is(".showMore-collapsed")) {
            $(this).text($(showMoreContainer).attr("data-showMoreText"));
        }
        else {
            $(this).text($(showMoreContainer).attr("data-showLessText"));
        }

        return false;
    });
}

function bindContactAnalytics() {
    $(".contact-container .contactme-btn").on('click', function () {
        var fullname = $(this).attr("data-fullname") || "";
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'contactme_button_clicked',
            'contactName': fullname,
            'pageUrl': window.location.href,
        });
    });
}

export function bindContacts() {
    bindContactAnalytics();
    bindContactShowMore();
}

$(function () {
    // Only run if any contact-info modals are present or we are in a contact us page
    if (!$(".contact-container .modal").length && !$(".contact-us-page").length) {
        return;
    }

    // Bind dynamically created modals + show more (contacts.js)
    bindContacts();
})
