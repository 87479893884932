const NAV_STRINGS = {
  mainToggle: ".js-main-navigation-toggle",
  container: ".js-mobile-nav",
  menuIcon: ".js-menu-icon",
  closeIcon: ".js-close-icon",
  hidden: "hidden",
  navActive: "navigation--active",
  ariaExpanded: "aria-expanded",
};

class Navigation {
  constructor() {
    this.toggleButton = document.querySelector(NAV_STRINGS.mainToggle);
    this.navContainer = document.querySelector(NAV_STRINGS.container);
    this.menuIcon = this.toggleButton.querySelector(NAV_STRINGS.menuIcon);
    this.closeIcon = this.toggleButton.querySelector(NAV_STRINGS.closeIcon);
    this.links =
      this.navContainer !== null
        ? Array.from(this.navContainer.querySelectorAll("a"))
        : [];
    this.firstLink = this.links[0];
    this.lastLink = this.links[this.links.length - 1];

    if (this.toggleButton !== null) {
      this.toggleButton.addEventListener("click", () => this.handleMenuClick());
    }

    if (this.navContainer !== null) {
      this.navContainer.addEventListener("keydown", (e) =>
        this.handleKeyDown(e)
      );
    }

    this.links.forEach((link) => {
      link.addEventListener("click", () => this.closeMenu());
    });
  }

  handleKeyDown(event) {
    const { key, target, shiftKey } = event;
    switch (key) {
      case "Escape":
        event.preventDefault();
        this.closeMenu();
        this.toggleButton.focus();
        break;
      case "Tab":
        event.preventDefault();
        shiftKey ? this.focusPreviousLink(target) : this.focusNextLink(target);
      default:
        break;
    }
  }

  handleMenuClick() {
    if (this.navContainer.classList.contains(NAV_STRINGS.hidden)) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  closeMenu() {
    this.navContainer.classList.remove(NAV_STRINGS.navActive);
    this.menuIcon.classList.remove(NAV_STRINGS.hidden);
    this.closeIcon.classList.add(NAV_STRINGS.hidden);
    setTimeout(() => {
      this.navContainer.classList.add(NAV_STRINGS.hidden);
    }, 300);
    this.toggleButton.setAttribute(NAV_STRINGS.ariaExpanded, "false");
  }

  openMenu() {
    this.navContainer.classList.remove(NAV_STRINGS.hidden);
    this.menuIcon.classList.add(NAV_STRINGS.hidden);
    this.closeIcon.classList.remove(NAV_STRINGS.hidden);
    setTimeout(() => {
      this.navContainer.classList.add(NAV_STRINGS.navActive);
    }, 10);
    this.toggleButton.setAttribute(NAV_STRINGS.ariaExpanded, "true");
  }

  focusPreviousLink(link) {
    if (link === this.firstLink) {
      this.lastLink.focus();
    } else {
      const i = this.links.indexOf(link);
      this.links[i - 1].focus();
    }
  }

  focusNextLink(link) {
    if (link === this.lastLink) {
      this.firstLink.focus();
    } else {
      const i = this.links.indexOf(link);
      this.links[i + 1].focus();
    }
  }
}

window.addEventListener("load", () => {
  if (document.querySelector(NAV_STRINGS.mainToggle)) {
    new Navigation();
  }
});