const addCopyToClipboardEventListener = () => {
  const copyLinkBtn = document.querySelector('.js-copy-link');
  const exportLink = document.querySelector('.js-export-link');

  if (!copyLinkBtn || !exportLink) {
    return;
  }

  copyLinkBtn.addEventListener('click', () => {
    navigator.clipboard.writeText(exportLink.textContent.replace(/\s/g, ''));
  });
};

const addTailoredExportEventListener = () => {
  const tailoredListCheckbox = document.querySelector('.js-tailored-export');
  const tailoredListOptions = document.querySelector('.js-tailored-export-list-options');

  if (!tailoredListCheckbox || !tailoredListOptions) {
    return;
  }
  
  if (!tailoredListCheckbox.checked) {
    tailoredListOptions.classList.add('hidden');
  }

  tailoredListCheckbox.addEventListener('change', (e) => {
    if (e.target.checked) {
      tailoredListOptions.classList.remove('hidden')
    } else {
      tailoredListOptions.classList.add('hidden');
    }
  });
};

window.addEventListener('load', () => {
  addTailoredExportEventListener();
  addCopyToClipboardEventListener();
});
