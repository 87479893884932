$(function () {
    if (!$('.search-page').length) {
        return;
    }

    function SearchAndSetResult(loadMore) {
        var $form = $('#search-query');
        var $allItems = $('#search-container');
        var $spinner = loadMore ? $("#spinnerLoadMore") : $("#spinnerSearch");
        var $msg = $(".search-result-text");
        if ($form.length && $allItems.length) {
            var q = "";
            var $queryInput = $('input[name="q"]')
            if ($queryInput.length) {
                q = $queryInput.val();
            }
            var filters = $.map($('input[name="search-filter"]:checked'), function (n, i) {
                return "&filters=" + n.value;
            }); 

            if (loadMore) {
                // Update page attribute. If we are loading more we need to fetch the next page.
                $form.data('currentPage', $form.data("currentPage") + 1);
            }
            else {
                // User has selected to filter items so we need to fetch the first page
                $form.data('currentPage', 1);
                // We need to remove all the old items before adding the new ones
                $allItems.empty();
            }

            let url = searchOptions.searchUrl + '?q=' + encodeURIComponent(q) + '&p=' + $form.data("currentPage");
            if (filters && filters.length > 0) {
                url += filters.join('');
            }

            $.ajax({
                url: url,
                type: "POST",
                contentType: "application/json; charset=utf-8",
                dataType: "html",
                beforeSend: function () {
                    // Show the spinner
                    if ($spinner) {
                        $spinner.show();
                    }
                    if ($msg) {
                        $msg.hide();
                    }
                }
            })
                .done(function (result) {
                    var html = $('<div />').append(result);
                    var $divItems = html.find("#more-items");
                    var totalCount = "0";
                    if ($divItems.length) {
                        var moreAvailable = $divItems.data("moreAvailable");
                        var items = $divItems.find('div.search-item-vertical');
                        if (items.length > 0) {
                            items.each(function () {
                                $allItems.append($(this));
                            });
                            // Do we need to hide load more button?
                            if (moreAvailable == "False") {
                                $('#search-load-more-container').hide();
                            }
                            else {
                                // We need to add the load more button
                                $('#search-load-more-container').show();
                            }

                            // Set the total result count
                            totalCount = $divItems.data("totalCount");

                            // Show the filters
                            $('.search-filters').removeClass("hidden");
                        }
                    }
                    else {
                        // No items found, let's hide load more button
                        $('#search-load-more-container').hide();
                    }
                    // Hide the spinner
                    if ($spinner) {
                        $spinner.hide();
                    }
                    // Set the result text
                    if ($msg) {
                        var resultText = searchOptions.searchResultText;
                        resultText = resultText.replace("{0}", q);
                        resultText = resultText.replace("{1}", totalCount);
                        $msg.html(resultText);
                        $msg.show();
                    }
                })
                .fail(function (error) {
                    console.error('Error:', error);
                    // Hide the spinner
                    if ($spinner) {
                        $spinner.hide();
                    }
                    // Set the result text
                    if ($msg) {
                        var resultText = searchOptions.searchErrorText;
                        $msg.html(resultText);
                        $msg.show();
                    }
                })
        }
    }

    $('#submit-search').on('click', function (e) {
        e.preventDefault();
        SearchAndSetResult(false);
        return false;
    });

    $('#search-load-more').on('click', function (e) {
        SearchAndSetResult(true);
        return false;
    });

    $('input[name="search-filter"]').on('click', function (e) {
        $('#search-query').data('page', 1);
        SearchAndSetResult(false);
    });

    $('#filter').on('click', function(e){
        var filterElement = $('#filtercolapse');
        if(filterElement.not(":visible")) {
            filterElement.toggleClass("d-none");
            filterElement.addClass("filter-border");
        } else {
                filterElement.removeClass("filter-border");
            }
    });
});